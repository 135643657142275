@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import './variables';
@import './utils';

@import './fonts';

html {
    font-size: 16px;
}

body {
    color: $main-text-color;
    font-family: 'BentonSans-Regular';

    &.disable-scrolling {
        overflow: hidden;
    }
}

header.no-topper + main {
    padding-top: $navigation-height-mobile;
}

main {
    padding-top: calc(#{$topper-height} + #{$navigation-height-mobile});
    line-height: 1.5;
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'BentonSans-Black';
        font-weight: normal;
        line-height: 1;
        color: $main-text-color;
        sup {
            font-family: 'BentonSans-Regular';
        }
        &.blue-gradient {
            color: #0080a5;
            background: -webkit-linear-gradient(62deg, #0080a5 20%, #a81b8d 80%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: inline-block;
            sup {
                color: #0080a5;
                -webkit-text-fill-color: #0080a5;
            }
        }
        &.purple-gradient {
            color: $main-second-text-color;
            background: -webkit-linear-gradient(62deg, #a81b8d 20%, #ff9015 80%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: inline-block;
            sup {
                display: inline;
                -webkit-background-clip: text;
                -webkit-text-fill-color: #a81b8d;
                color: inherit;
                top: -.2em;
            }
        }
        &.text-purple {
            color: $main-second-text-color;
            background-color: transparent;
        }
    }
    h2 {
        font-size: 2rem;
        margin-bottom: .75em;
    }
    h3 {
        font-size: 1.5rem;
        line-height: 1.25;
    }

    p {
        font-size: 1rem;
        b, strong {
            font-family: 'BentonSans-Medium';
            font-weight: normal;
        }
        a {
            color: inherit;
            text-decoration: underline;

            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }

        &.bullet {
            position: relative;
            padding-left: 2rem;
            &:before {
                content: '\2022';
                position: absolute;
                display: inline-block;
                left: 0.5rem;
            }
        }
    }
    ul, ol {
        font-size: 1rem;
    }
    ul {
        li {
            a {
                color: inherit;
                text-decoration: underline;

                &:hover {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
    }

    a {
        &.download, &.read-more {
            display: inline-block;
            font-family: 'BentonSans-Black';
            color: $main-second-text-color;
            text-decoration: none;
            font-size: .875rem;
            white-space: nowrap;
            span {
                white-space: normal;
                display: inline;
            }
            &:hover {
                color: $main-second-text-color;

                span {
                    text-decoration: underline;
                }
            }
        }
        &.read-more {
            &:after {
                content: '\003E';
                padding-left: .35rem;
                display: inline-block;
                transform: translateX(0);
                transition: transform 1.3s ease-out;
            }
            &:hover {
                &:after {
                    animation-timing-function: ease-out;
                    animation-duration: 1.3s;
                    animation-iteration-count: infinite;
                    animation-name: read-more-animation;
                }
            }
        }
        &.download {
            &:after {
                content: '\e902';
                font-family: 'ogn-puraply';
                padding-left: .35rem;
                display: inline-block;
            }
            &:hover {
                &:after {
                    animation-timing-function: ease;
                    animation-duration: 1.3s;
                    animation-iteration-count: infinite;
                    animation-name: download-animation;
                }
            }
        }
    }

    .link-download {
        &:after {
            content: '\e902';
            font-family: 'ogn-puraply';
            padding-left: .35rem;
            display: inline-block;
        }
        &:hover {
            &:after {
                animation-timing-function: ease;
                animation-duration: 1.3s;
                animation-iteration-count: infinite;
                animation-name: download-animation;
            }
        }
    }

    ul {
        padding-top: 0.75em;
        padding-left: 2em;
        margin-bottom: 1.25rem;
        max-width: 100%;

        li {
            margin-bottom: 0.375em;
        }

        &.columns-1 {
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count: 1;
        }

        &.columns-2 {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
        }
        & > *:last-child {
            margin-bottom: 0;
        }
    }
    hr {
        width: calc(100% - 2rem);
        margin: 0 auto;
        padding: 0;
        border-color: $main-second-text-color;
    }
    small {
        font-size: .75rem;
        &.footnote {
            display: block;
            font-family: 'BentonSans-Book';
            line-height: 1.45em;
            a {
                text-decoration: underline;
                color: inherit;
                &:hover {
                    text-decoration: none;
                }
            }
            &.with-sup {
                padding-left: .5em;
                margin-left: 0;
                position: relative;
                sup:first-child {
                    position: absolute;
                    left: 0;
                    top: .5em;
                }
            }
        }
    }
    .component-alt-bg {
        background-color: $component-alt-bg-color;
    }
    table {
        &.table {
            thead {
                background-color: $main-second-text-color;
                color: #ffffff;
                font-family: 'BentonSans-Medium';
                th {
                    border-top: none;
                    border-bottom: none;
                    white-space: nowrap;
                    font-size: 0.875rem;
                }
            }
            tbody {
                tr {
                    td {
                        border-top: none;
                        border-bottom: 2px solid #605c5b;
                        font-size: 0.875rem;
                    }
                    &:last-child {
                        td {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
    .container > *:last-child {
        margin-bottom: 0;
    }
    .container > .component-content-block:last-child {
        padding-bottom: 0;
    }
    .text-thin {
        font-family: 'BentonSans-Book';
    }
    .btn {
        text-decoration: none;
    }
}

.modal-body {
    h1 {
        font-size: 2rem;
    }
}

form {

    label,
    legend {
        display: inline-block;
        font-family: 'BentonSans-Medium';
        font-size: 1.1em;

        span {
            font-family: 'BentonSans-Book';
            font-size: 0.85em;
        }
        b, strong {
            font-family: 'BentonSans-Bold';
            font-weight: normal;
        }
    }

    legend {
        padding-left: 1.875em;
        font-size: 1em;
    }

    .form-check {
        label {
            font-family: 'BentonSans-Book';
        }

        input {
            margin-top: .05rem;
        }
    }

    .custom-checkbox,
    .custom-radio {
        padding-left: 0;

        input[type="checkbox"],
        input[type="radio"] {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        input[type="radio"]:checked+label:after {
            background-color: $main-text-color;
        }

        input[type="checkbox"]:checked+label:after {
            content: '\2713';
            top: 6px;
            color: $main-text-color;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            font-size: 0.75rem;
            width: initial !important;
        }

        label {
            display: inline-block;
            position: relative;
            padding-left: 1.75em;
            font-size: 1.1rem;
            line-height: 1.75em;
            font-family: 'BentonSans-Book';
            padding-top: 5px;
            padding-bottom: 0;
            width: initial !important;

            &:before {
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 18px;
                height: 18px;
                pointer-events: none;
                content: "";
                background-color: white;
                border: 2px solid $main-text-color;
                transform: translateY(-50%);
            }

            &:after {
                position: absolute;
                left: 4px;
                display: block;
                width: 10px;
                height: 10px;
                content: "";
                background-color: transparent;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .custom-radio {
        label {
            &:before {
                border-radius: 50%;
            }

            &:after {
                border-radius: 50%;
            }
        }
    }

    .custom-checkbox {
        label {
            &:before {
                top: 6px;
                transform: none;
            }

            &:after {
                transform: none;
            }
        }
    }
    .form-error {
        color: $main-second-text-color;
        display: none;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        .icon {
            font-size: 22px;
            width: 22px;
            height: 22px;
            margin-right: 10px;
            flex-grow: 0;
        }
        span {
            font-family: 'BentonSans-Medium';
            flex-grow: 1;
            flex-shrink: 1;
            line-height: 1.2;
        }
    }
    fieldset {
        margin-bottom: 1rem;
        &.form-group {
            display: block;
        }
    }

    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    select {
        font-size: 1rem;
        padding: 0.75rem;
        height: 50px;
        border: 1px solid $main-text-color;
        border-radius: 0;
    }

    textarea {
        font-size: 1rem;
        padding: 0.75rem;
        max-height: 200px;
        min-height: 100px;
        width: 100%;
        border: 1px solid $main-text-color;
    }

    .form-select {
        visibility: hidden;
        position: absolute;
    }

    button[type="submit"] {
        border: 2px solid transparent;

        &:hover {
            color: $main-link-color;
            background-color: white;
            border: 2px solid $main-second-text-color;
        }
    }

    .form-group {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        & > label {
            width: 100%;
        }
        & > input,
        & > .component-select {
            width: calc(100% - 37px);
        }
        & > .error-icon {
            color: $main-second-text-color !important;
            display: none;
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 22px;
            width: 22px;
            height: 22px;
            margin: 0 5px 0 10px;
        }
        & > .error-holder {
            font-family: 'BentonSans-Medium';
            color: $main-second-text-color !important;
            display: none;
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
        }
        &.error {
            & > label {
                color: $main-second-text-color !important;
            }
            & > input,
            & > .component-select {
                border: 1px solid $main-second-text-color !important;
            }
            & > .error-icon {
                display: block;
            }
            & > .error-holder {
                display: block;
            }
        }
        small {
            font-size: .8em;
        }
    }
    .form-submit {
        span {
            font-family: 'BentonSans-Regular';
            display: none;

            &.error {
                color: $main-second-text-color;
            }
        }
        button.btn[disabled] {
            background: #E6E6E6 !important;
            color: #B8B8B8 !important;
            border-color: transparent !important;
            cursor: not-allowed;
        }

        &.disabled {
            .processing {
                display: block;
            }
        }

        &.error {
            .error {
                display: block;
            }
        }
    }

    &>div:not(.form-group) {
        .form-check {
            label {
                .font-weight-bold {
                    font-family: 'BentonSans-Medium';
                }

                font-family: 'BentonSans-Regular';
                line-height: 1.25em;
                font-size: 1rem;
            }
        }

        p {
            font-family: 'BentonSans-Regular';
            line-height: 1.25em;
            a {
                &:hover {
                    text-decoration: none;
                }

                font-family: 'BentonSans-Medium';
                text-decoration: underline;
            }
            b, strong {
                font-family: 'BentonSans-Medium';
            }
        }

        &.visible {
            display: block;
        }

        display: none;
    }
    &.has-errors {
        .form-error {
            display: flex;
        }
    }
}

.mw-video {
    max-width: 640px !important;
}

.link-video-thumb {
    text-decoration: none;
    transition: opacity ease-out 0.25s;
    &:hover {
        text-decoration: none;
        opacity: 0.875
    }
}
.link-video-play {
    text-decoration: none;
    font-family: 'BentonSans-Medium';
    img {
        height: 22px;
        margin-left: 0.5rem;
        margin-bottom: 0.2rem;
    }
    &:hover {
        text-decoration: underline;
    }
}
.b-pdf-download {
    cursor: pointer;
    .b-topper {
        height: 1.25rem;
        background-color: $main-link-color;
    }
    .b-main {
        padding: 1.5rem 2rem;
        text-decoration: none;
        transition: opacity ease-out 0.25s;
        background-color: #f0f0f0 !important;
        color: $secondary-gray;
        &:hover {
            text-decoration: none;
            opacity: 0.875;
        }
        .b-top {
            height: 4.5rem;
            margin-bottom: 2rem;
            color: $secondary-gray;
            font-size: 0.9rem;
            font-family: 'BentonSans-Medium' !important;
            line-height: 1rem;
            .icon-pdf {
                height: 3.5rem;
            }
        }
        .b-center {
            height: 6rem;
            margin-bottom: 3rem;
            color: $secondary-gray;

            h3 {
                font-size: 1.75rem;
                font-family: 'BentonSans-Bold' !important;
            }

            p {
                font-family: 'BentonSans-Regular' !important;
                font-size: 0.95rem;
            }
        }
        .b-bottom {
            font-family: 'BentonSans-Bold';
            color: $secondary-gray;
            font-size: 0.85rem;
        }
    }
}

@supports (-webkit-appearance: none) {
    form {
        input {
            &[type="text"],
            &[type="email"],
            &[type="number"],
            &[type="number"]::-webkit-outer-spin-button,
            &[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }
    }
}

@supports (-moz-appearance: textfield) {
    form {
        input[type=number] {
            -moz-appearance: textfield;
        }
    }
}

@include media-breakpoint-up(sm) {
    main {
        h2 {
            font-size: 2.375rem;
        }
        p {
            font-size: 1.0625rem;
        }
        a {
            &.read-more, &.download {
                font-size: .9375rem;
            }
        }
        ul, ol {
            font-size: 1.0625rem;
        }
        hr {
            max-width: 480px;
        }
    }
}

@include media-breakpoint-up(md) {
    main {
        .container {
            padding-left: 70px;
            padding-right: 70px;
        }
        hr {
            max-width: 660px;
        }
        ul {
            &.columns-md-1 {
                -webkit-column-count: 1;
                -moz-column-count: 1;
                column-count: 1;
            }

            &.columns-md-2 {
                -webkit-column-count: 2;
                -moz-column-count: 2;
                column-count: 2;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    header.no-topper + main {
        padding-top: $navigation-height-desktop;
    }
    main {
        padding-top: calc(#{$topper-height} + #{$navigation-height-desktop});
        .container {
            padding-left: 85px;
            padding-right: 85px;
        }
        hr {
            max-width: 900px;
        }
        ul {
            &.columns-lg-1 {
                -webkit-column-count: 1;
                -moz-column-count: 1;
                column-count: 1;
            }

            &.columns-lg-2 {
                -webkit-column-count: 2;
                -moz-column-count: 2;
                column-count: 2;
            }
        }
        .w-lg-25 {
            width: 25% !important;
        }
        table {
            &.table {
                thead {
                    th {
                        font-size: 1rem;
                    }
                }
                tbody {
                    tr {
                        td {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
    form {
        .form-group {
            & > .error-holder {
                width: auto;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    main {
        h2 {
            font-size: 2.875rem;
            margin-bottom: .5em;
        }
        hr {
            max-width: 1080px;
        }
    }
}

sup.required {
  color: red;
}

label:has(+ input[data-validators~="phone"]),
label:has(+ input[data-validators~="email"]),
label:has(+ input[data-validators~="required"]),
label:has(+ textarea[data-validators~="required"]),
label:has(+ select[data-validators~="required"]) {
  &:after {
    display: inline-block;
    padding-left: .2rem;
    content: " *";
    color: red;
  }
}
