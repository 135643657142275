@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';
.component-intro {
    text-align: center;
    padding-top: $content-padding-top;
    .container {
        h2 {
            color: $main-second-text-color;
            font-family: 'BentonSans-Medium';
            font-size: 1.15rem;
            line-height: 1.5;
            margin-bottom: 0;
        }
        p {
            margin-bottom: 1em;
        }
        .anchor-link {
            text-decoration: none;
            span {
                text-decoration: underline;
            }
            &:hover {
                span {
                    text-decoration: none;
                }
            }
        }
        & > *:last-child {
            margin-bottom: 0 !important;
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-intro {
        .container {
            h2 {
                font-size: 1.35rem;
                margin-bottom: .45em;
            }
            p {
                margin-bottom: 2em;
            }
        }
    }
}
