@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.component-contact {
    background: linear-gradient(270deg, #56529f, #e00995);
    background-size: 200% 200%;
    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding-top: $content-padding-top;
    padding-bottom: $content-padding-bottom;
    .container {
        h2, p {
            color: #ffffff;
        }
        h2 {
            font-family: 'BentonSans-Black';
            font-size: 1.85rem;
            line-height: 1em;
        }
        p {
            font-family: 'BentonSans-Book';
            font-size: 1.125rem;
            line-height: 1.5em;
            letter-spacing: -.005em;
            margin-bottom: 2rem;
            a {
                color: #ffffff;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .btn {
            background-color: #ffffff;
            border-radius: 0;
            color: $main-second-text-color;
            padding: 10px 30px;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-contact {
        .container {
            h2 {
                font-size: 2.5rem;
                line-height: 1.05em;
                margin-bottom: 2rem;
            }
            p {
                margin-bottom: 2rem;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-contact {
        .container {
            h2 {
                margin-bottom: 2.75rem;
            }
            p {
                font-size: 1.1875rem;
                margin-bottom: 2.75rem;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-contact {
        .container {
            p {
                font-size: 1.3125rem;
            }
        }
    }
}