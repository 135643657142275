@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.component-perspective-paper-series {
    .image-holder {
        text-align: left;
        margin-bottom: 1.5rem;
        img {
            max-width: 50%;
            margin-right: .5rem;
            width: 130px;
        }
    }
    .person-name {
        display: block;
        font-family: 'BentonSans-Medium';
        line-height: 1.25;
    }
}
@include media-breakpoint-up(sm) {
    .component-perspective-paper-series  {
        .image-holder {
            img {
                width: 150px;
            }
        }
    }
}