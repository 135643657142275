
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';
.component-results-chart {
    text-align: center;
    h4 {
        margin: 0;
        font-family: 'BentonSans-Bold';
        color: $main-second-text-color;
    }
    p {
        margin: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        font-family: 'BentonSans-Medium';
    }
    & > *:last-child {
        padding-bottom: 0 !important;
    }
}
@include media-breakpoint-up(md) {
    .component-results-chart {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;
        p {
            padding-bottom: 0;
        }
        & > * {
            flex: 1 1 33.33%;
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-results-chart {
        p {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}