@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-significant {
    text-align: center;
    h2, h3, h4, h5, h6, .paragraph {
        font-family: 'BentonSans-Bold';
        color: $main-second-text-color;
        max-width: 100%;
        &.font-weight-bold {
            font-family: 'BentonSans-Black';
            font-weight: normal !important;
        }
    }
    h3 {
        font-size: 2rem;
    }
    h4 {
        font-size: 1.85rem;
    }
    h5 {
        font-size: 1.5rem;
        line-height: 1.2;
    }
    h6 {
        font-size: 1.15rem;
        line-height: 1.2;
    }
    .paragraph {
        font-size: 0.9375rem;
        line-height: 1.2;
        margin-bottom: 0;
    }
    .infographic {
        .col-12 {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .image-holder {
                flex-grow: 0;
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 90px;
                width: 120px;
                margin-right: 1rem;
                img {
                    max-height: 100%;
                    max-width: 100%;
                    flex-shrink: 0;
                }
            }
        }
        &.vertical {
            .col-12 {
                flex-direction: column;
                justify-content: flex-start;
                .image-holder {
                    width: 180px;
                    height: 140px;
                    margin-right: 0;
                }
            }
        }
    }
    &.infographic-full-orange, &.infographic-full-purple {
        position: relative;
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;

        text-align: left;

        h3 {
            font-size: 1.65rem;
        }
        .left {
            padding-top: $content-padding-top;
            padding-bottom: $content-padding-bottom;
            padding-left: 15px;
            width: 40%;
            background: linear-gradient(200deg,#f27e27 0%, #e1673e 25%, #cd4d5a 50%, #ba3374 75%, #ae2384 100%);

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            .line {
                width: 100%;
                flex-grow: 1;
                flex-shrink: 0;

                padding-top: 20px;
                padding-bottom: 20px;
                padding-right: 30px;
                position: relative;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;

                * {
                    color: #ffffff;
                }

                &:before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: calc(50% - 30px);
                    width: 0;
                    height: 0;
                    border-top: 30px solid transparent;
                    border-bottom: 30px solid transparent;
                    border-right: 20px solid #fff;
                }
            }
        }
        .right {
            padding-top: $content-padding-top;
            padding-bottom: $content-padding-bottom;
            padding-right: 15px;
            width: 60%;
            background-color: #ffffff;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            .line {
                width: 100%;
                flex-grow: 1;

                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 1rem;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
            }
        }
    }
    &.infographic-full-purple {
        .left {
            background: linear-gradient(62deg, #0080a5 20%, #a81b8d 80%);
        }
    }
    &.video-callout {
        h3 {
            font-size: 1.65rem;
            color: #fff;
        }
        .container-backgrounds {
            display: none;
        }
        .content-container {
            overflow: hidden;
            .left {
                padding: 90px 2rem 110px 2rem;
                color: #ffffff;
                &::before {
                    display: block;
                    content: '';
                    left: -10px;
                    bottom: 0;
                    position: absolute;
                    width: 50%;
                    height: 20px;
                    transform: skew(45deg, 0);
                    background: linear-gradient(90deg, #eeeeee 10%, #ffffff);
                }
                &::after {
                    display: block;
                    content: '';
                    right: -10px;
                    bottom: 0;
                    position: absolute;
                    width: 50%;
                    height: 20px;
                    transform: skew(-45deg, 0);
                    background: linear-gradient(90deg, #ffffff, #eeeeee 90%);
                }
                background: linear-gradient(200deg,#f27e27 0%, #e1673e 25%, #cd4d5a 50%, #ba3374 75%, #ae2384 100%);
            }
            .right {
                padding: 70px 2rem 90px 2rem;
                background: linear-gradient(90deg, #eeeeee 10%, #ffffff, #eeeeee 90%);
                .content {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    .video-container {
                        flex: 1 0 auto;
                    }
                }
            }
        }
    }
    &.image-text {
        h3 {
            font-size: 1.5rem;
            font-family: 'BentonSans-Black';
        }
        h4 {
            font-size: 1.15rem;
            font-family: 'BentonSansCond-Bold';
            margin-bottom: 0.25rem;
        }
        p {
            color: #a81b8d;
            font-size: 0.875rem;
        }
        .references {
            font-size: 0.75rem;
            font-family: 'BentonSansCond-Regular';
            color: #64686c;
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-significant {
        &.infographic-full-orange, &.infographic-full-purple {
            h3 {
                font-size: 2rem;
            }
            h4 {
                font-size: 1.85rem;
            }
            h5 {
                font-size: 1.5rem;
            }
            h6 {
                font-size: 1.15rem;
            }
            .paragraph {
                font-size: 0.9375rem;
            }
        }
        &.video-callout {
            .content-container {
                .left {
                    padding: 90px 7rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-significant {
        .infographic {
            .col-12 {
                justify-content: center;
                .image-holder {
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }
            &.vertical {
                .col-12 {
                    .image-holder {
                        justify-content: center;
                    }
                }
            }
        }
        &.infographic-full-orange, &.infographic-full-purple {
            .left {
                .line {
                    width: 218px;
                }
            }
            .right {
                .line {
                    width: 382px;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-significant {
        &.infographic-full-orange, &.infographic-full-purple {
            .left {
                .line {
                    width: 192px;
                }
            }
            .right {
                .line {
                    padding-left: 2rem;
                    width: 480px;
                }
            }
        }

        &.video-callout {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            text-align: left;
            overflow: hidden;

            .container-backgrounds {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                &::before {
                    display: block;
                    height: 100%;
                    content: '';
                    width: calc(50% + 10px);
                    background: linear-gradient(62deg, #0080a5 20%, #a81b8d 80%);
                }

                &::after {
                    display: block;
                    height: 100%;
                    content: '';
                    width: calc(50% - 10px);
                    background: linear-gradient(90deg, #eeeeee 10%, #ffffff, #eeeeee 90%);
                }
            }
            .content-container {
                .left {
                    padding: 5rem 6rem 5rem 0;
                    background: transparent !important;
                    h3 {
                        color: #ffffff;
                    }
                    &::before {
                        display: block;
                        content: '';
                        right: -10px;
                        top: -10px;
                        left: initial;
                        bottom: initial;
                        position: absolute;
                        width: 20px;
                        height: 50%;
                        transform: skew(0, 45deg);
                        background: #eeeeee;
                    }
                    &::after {
                        display: block;
                        content: '';
                        right: -10px;
                        bottom: -10px;
                        position: absolute;
                        width: 20px;
                        height: 50%;
                        transform: skew(0, -45deg);
                        background: #eeeeee;
                    }
                    .link-video-play {
                        img {
                            margin-left: 0.667rem;
                        }
                    }
                }
                .right {
                    background: transparent !important;
                    padding: 0;

                    .content {
                        position: relative;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        height: 100%;
                        .video-container {
                            flex: 0.9 0 auto;
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-significant {
        &.infographic-full-orange, &.infographic-full-blue {
            .left {
                .line {
                    width: 228px;
                }
            }
            .right {
                .line {
                    padding-left: 3rem;
                    width: 570px;
                }
            }
        }
    }
}
