@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../css/variables';

.template-home {
    .component-intro {
        padding-bottom: 90px;
    }
    .home-parallax {
        .parallax-animated {
            opacity: 0;
            transform: translateY(10px);
            transition: opacity .3s ease-out, transform .3s ease-out;
        }
        &.visible {
            .parallax-animated {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}
