@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import '../css/variables';

.template-subpage {
    .component-breadcrumb + .component-content {
        padding-top: 70px;
    }
}
@include media-breakpoint-up(lg) {
    .template-subpage {
        .component-breadcrumb + .component-content {
            padding-top: 80px;
        }
    }
}