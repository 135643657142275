$topper-height: 0px;

$navigation-height-mobile: 100px;
$navigation-height-mobile-small: 80px;
$navigation-height-general-mobile: 100px;
$navigation-height-general-mobile-small: 80px;
$navigation-height-desktop: 195px;
$navigation-height-desktop-small: 80px;
$navigation-height-products-desktop: 55px;
$navigation-height-products-desktop-small: 0px;
$navigation-height-general-desktop: 140px;
$navigation-height-general-desktop-small: 80px;

$navigation-color: #64666b;

$bg-color-blue: #0080a5;
$bg-color-purple: #a81b8d;

$products-nav-main-color: #ffffff;
$products-nav-main-text-color: #44474b;
$products-nav-second-text-color: #64666b;
$products-nav-borders-color: #d7d7d7;

$general-nav-main-color: #a81b8d;
$general-nav-main-transparent-color: rgba(168,27,141,.93);
$general-nav-main-text-color-desktop: #64666b;
$general-nav-main-text-color-mobile: #ffffff;
$general-nav-second-text-color: #ffffff;

$main-text-color: #64666b;
$main-second-text-color: #a81b8d;
$main-link-color: #a81b8d;
$main-third-text-color: #99ca3c;
$main-forth-text-color: #ff9015;
$main-second-tab-text-color: #64666b;

$template-home-text-color: #454649;
$component-alt-bg-color: #f4f7f9;

$content-padding-top: 90px;
$content-padding-bottom: 90px;

$select-variants-height: 200px;

$secondary-gray: #555555;

$footer-bg-deep-blue: #041c2c;
$footer-bg-dark-blue: #002855;
$footer-color: #fff;