@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-important {
    padding-top: $content-padding-top;
    padding-bottom: $content-padding-bottom;
    background: linear-gradient(25deg, #56529f, #e00995);
    .container {
        h2, p, .read-more {
            color: #ffffff;
        }
        h2 {
            font-family: 'BentonSans-Black';
            font-size: 1.85rem;
            line-height: 1em;
        }
        p {
            font-family: 'BentonSans-Book';
            font-size: 1.125rem;
            line-height: 1.5em;
            letter-spacing: -.005em;
            margin-bottom: 2rem;
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-important {
        .container {
            h2 {
                font-size: 2.5rem;
                line-height: 1.05em;
                margin-bottom: 2rem;
            }
            p {
                margin-bottom: 2rem;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-important {
        .container {
            h2 {
                margin-bottom: 2.75rem;
            }
            p {
                font-size: 1.1875rem;
                margin-bottom: 2.75rem;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-important {
        .container {
            p {
                font-size: 1.3125rem;
            }
        }
    }
}
