@keyframes read-more-animation {
    0% {
      transform: translateX(0);
    }
  
    50% {
        transform: translateX(5px);
    }
  
    100% {
        transform: translateX(0);
    }
}

@keyframes download-animation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5px);
    }
}

// @keyframes fluid-bg-gradient {
//     0% {
//         background-position: 0% 50%
//     }
//     50% {
//         background-position: 100% 50%
//     }
//     100% {
//         background-position: 0% 50%
//     }
// }

@-webkit-keyframes fluid-bg-gradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes fluid-bg-gradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes fluid-bg-gradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}