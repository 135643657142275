@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';
.component-schema {
    &.provider-support {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        line-height: 1;
        .schema-item {
            text-align: center;
            margin-bottom: 2rem;
            line-height: 1.3333em;
            .image-holder {
                width: 100%;
                height: 70px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 2.25rem;
                img {
                    max-width: 50px;
                    max-height: 50px;
                }
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-schema {
        &.provider-support {
            .schema-item {
                a {
                    font-size: 1rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-schema {
        &.provider-support {
            flex-direction: row;
            flex-wrap: wrap;
            .schema-item {
                width: 50%;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-schema {
        &.provider-support {
            flex-wrap: nowrap;
            .schema-item {
                width: 25%;
                margin-bottom: 0;
            }
        }
    }
}
