@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import './variables';
@import './animations';

a sup {
    display: inline-block;
    text-decoration: none;
}
h2.text-red {
    color: $main-second-text-color;
    font-family: 'BentonSans-Medium';
    font-size: 1.15rem;
    line-height: 1.5;
    margin-bottom: 0;
}
.shadow-top {
    position: relative;
    overflow: hidden;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        height: 10px;
        box-shadow: 0 1px 20px rgba(0,0,0,.2);
    }
}
.shadow-bottom {
    position: relative;
    overflow: hidden;
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        height: 10px;
        box-shadow: 0 -1px 20px rgba(0,0,0,.2);
    }
}
.btn {
    border-radius: 0;
    color: $main-link-color;
    font-family: 'BentonSans-Medium';
    font-size: .9375rem;
    padding: .65rem 1.35rem .5rem;
    &:focus {
        box-shadow: none;
    }
}
.btn-red {
    background-color: $main-second-text-color;
    color: white;
    border: 2px solid transparent;

    &:hover {
        color: $main-link-color;
        background-color: white;
        border: 2px solid $main-second-text-color;
    }
}
.text-red {
    color: $main-second-text-color;
}
.text-orange {
    color: $main-forth-text-color;
}
.icon-orange {
    color: transparent;
    text-shadow: 0 0 0 $main-forth-text-color;
}
.bg-gray-gradient {
    background-image: linear-gradient(51deg, #eeeeee 10%, #ffffff, #eeeeee 90%);
}
.bg-orange-gradient {
    background: $general-nav-main-color !important;
    background: linear-gradient(45deg, $general-nav-main-color 0%, $main-forth-text-color 80%) !important;
}
.bg-blue-purple-fluid {
    background: linear-gradient(270deg, $bg-color-blue, $bg-color-purple);
    background-size: 200% 200%;
    -webkit-animation: fluid-bg-gradient 10s ease infinite;
    -moz-animation: fluid-bg-gradient 10s ease infinite;
    animation: fluid-bg-gradient 10s ease infinite;
}
.bg-purple {
    background-color: $main-link-color;
}
.mw-25 {
    max-width: 25% !important;
}
.mw-50 {
    max-width: 50% !important;
}
.mw-75 {
    max-width: 75% !important;
}
.text-break {
    word-break: break-all !important;
    overflow-wrap: break-word !important;
}
.br-desktop {
    display: inline;
    &:before {
        content: ' ';
    }
}
.br-mobile {
    display: block;
    height: 0;
    overflow: hidden;
    &:before {
        content: none;
    }
}
.bordered {
    border: 2px solid $main-text-color;
}
.cta-inline {
    color: $main-second-text-color;
    font-size: 0.95rem;
}
.video-img-max {
    max-width: 600px;
    width:100%;
}
.video-border {
    border: 1px solid $main-text-color;
}
@include media-breakpoint-up(sm) {
    .mw-sm-25 {
        max-width: 25% !important;
    }
    .mw-sm-50 {
        max-width: 50% !important;
    }
    .mw-sm-75 {
        max-width: 75% !important;
    }
    .mw-sm-100 {
        max-width: 100% !important;
    }
}
@include media-breakpoint-up(md) {
    .mw-md-25 {
        max-width: 25% !important;
    }
    .mw-md-50 {
        max-width: 50% !important;
    }
    .mw-md-75 {
        max-width: 75% !important;
    }
    .mw-md-90 {
        max-width: 90% !important;
    }
    .mw-md-100 {
        max-width: 100% !important;
    }
}
@include media-breakpoint-up(lg) {
    .mw-lg-25 {
        max-width: 25% !important;
    }
    .mw-lg-40 {
        max-width: 40% !important;
    }
    .mw-lg-50 {
        max-width: 50% !important;
    }
    .mw-lg-75 {
        max-width: 75% !important;
    }
    .mw-lg-90 {
        max-width: 90% !important;
    }
    .mw-lg-100 {
        max-width: 100% !important;
    }
    .w-lg-100 {
        width: 100% !important;
    }
    .br-desktop {
        display: block;
        height: 0;
        overflow: hidden;
        &:before {
            content: none;
        }
    }
    .br-mobile {
        display: inline;
        &:before {
            content: '';
        }
    }
    .btn {
        font-size: 1rem;
    }
}
@include media-breakpoint-up(xl) {
    .mw-xl-25 {
        max-width: 25% !important;
    }
    .mw-xl-50 {
        max-width: 50% !important;
    }
    .mw-xl-75 {
        max-width: 75% !important;
    }
    .mw-xl-90 {
        max-width: 90% !important;
    }
    .mw-xl-100 {
        max-width: 100% !important;
    }
}