@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';
.component-map {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        #map {
            display: none;
            width: 100%;
            margin-bottom: 3rem;
        }
        .form-group {
            width: 100%;
            margin-bottom: 3rem;
            .component-select {
                border-color: $main-second-text-color;
                background-color: $main-second-text-color;
                font-family: 'BentonSans-Medium';
                & > span {
                    color: #ffffff;
                }
                & > button {
                    background: $main-second-text-color;
                    &:before {
                        border-color: transparent transparent #ffffff transparent;
                        opacity: 1;
                    }
                    &:after {
                        border-color: #ffffff transparent transparent transparent;
                        opacity: 1;
                    }
                }
                ul {
                    border: 1px solid $main-second-text-color;
                    padding: 0;
                    li {
                        color: $main-second-text-color;
                        border-bottom: 1px solid $main-second-text-color;
                        &:hover {
                            color: #ffffff;
                        }
                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
        .documents {
            width: 100%;
        }
}
@include media-breakpoint-up(sm) {
    .component-map {
        #map {
            display: block;
            width: 510px;
            height: 327px;
        }
    }
}
@include media-breakpoint-up(md) {
    .component-map {
        #map {
            width: 580px;
            height: 372px;
        }
        .form-group {
            width: 75%;
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-map {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        #map {
            width: 790px;
            height: 507px;
            flex-shrink: 0;
        }
        .form-group {
            width: 35%;
            flex-shrink: 1;
            align-self: stretch;
            .component-select {
                .select-variants {
                    &.active {
                        max-height: 250px;
                        ul {
                            height: 250px;
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-map {
        #map {
            width: 970px;
            height: 622px;
        }
        .form-group {
            .component-select {
                .select-variants {
                    &.active {
                        max-height: 391px;
                        ul {
                            height: 391px;
                        }
                    }
                }
            }
        }
    }
}
