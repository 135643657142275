@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-content-block {
    border-top: 4px solid $main-second-text-color;
    padding: 1rem 0 3rem 0;
    border-radius: 0;

    h2 {
        padding-top: .5rem;
        font-family: 'BentonSans-Black';
        font-size: 1rem;
        margin-bottom: .5rem;
        text-align: left;
        color: $main-second-text-color;
        line-height: 1.2em;
        white-space: normal;
    }
}

@include media-breakpoint-up(sm) {
    .component-content-block {
        h2 {
            font-size: 1.15rem;
        }
    }
}
