@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';

#component-navigation {
    height: $navigation-height-mobile;
    transition: height .3s ease-in;
    border-bottom: 1px solid $navigation-color;
    box-shadow: 0px 2px 5px 0.3px rgba(0, 0, 0, .11);
    background-color: #fff;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        &.icon-products {
            background-image: url(/images/icon-products.svg);
        }
        &.icon-menu {
            background-image: url(/images/icon-menu.svg);
        }
    }
    .navbar {
        padding: 0 1rem;
        height: $navigation-height-general-mobile;
        transition: height .3s ease-in;
        .navbar-brand {
            margin-right: .5rem;
            img {
                height: 50px;
            }
        }
        .navbar-actions-mobile {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .navbar-open {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: none;
                min-height: 20px;
                margin-left: .75rem;
                padding: 0;
                outline: none;
                span {
                    font-family: 'BentonSans-Regular';
                    font-size: 1rem;
                    letter-spacing: .02em;
                    color: $navigation-color;
                    &.icon {
                        margin-right: .5rem;
                    }
                    &:not(.icon) {
                        position: relative;
                        top: 1px;
                    }
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    .navbar-menu-desktop {
        display: none;
    }
    .navbar-menu-mobile {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $navigation-height-general-mobile;
        &.opened {
            display: block;
        }
        &#navigation-products-mobile {
            background-color: $products-nav-main-color;
            .container {
                .navbar-menu-mobile-heading {
                    background-color: $products-nav-main-color;
                    span {
                        color: $products-nav-second-text-color;
                    }
                    .icon-close {
                        background-image: url(/images/icon-close-gray.svg);
                    }
                }
                .navbar-menu-mobile-body {
                    list-style: none;
                    margin: 0;
                    li {
                        border-bottom: 1px solid $products-nav-borders-color;
                        width: 100%;
                        .navbar-product-link {
                            display: block;
                            padding: 1.2rem .5rem;
                            font-family: 'Gotham-Book';
                            font-size: 1.8243rem;
                            color: $products-nav-main-text-color;
                            cursor: pointer;
                            opacity: .6;
                            transition: opacity .3s ease-in;
                            &:hover {
                                text-decoration: none;
                                opacity: 1;
                            }
                            &.disabled {
                                cursor: default;
                                opacity: 1;
                            }
                            sup {
                                font-size: .5em;
                                position: relative;
                                top: -1em;
                            }
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        &#navigation-general-mobile {
            background-color: $general-nav-main-color;
            .container {
                .navbar-menu-mobile-heading {
                    background-color: $general-nav-main-color;
                    span {
                        color: $general-nav-main-text-color-mobile;
                    }
                    .icon-close {
                        background-image: url(/images/icon-close-white.svg);
                    }
                }
                .navbar-menu-mobile-body {
                    .accordion {
                        margin-bottom: 3rem;
                        border-bottom: 1px solid $general-nav-main-text-color-mobile;
                        .card {
                            background-color: transparent;
                            border-radius: 0;
                            border-width: 0;
                            .card-header {
                                padding: 0;
                                border-radius: 0;
                                background-color: transparent;
                                border-bottom: 0;
                                h2 {
                                    border-top: 1px solid $general-nav-main-text-color-mobile;
                                    margin-bottom: 1px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    align-items: center;
                                    a {
                                        flex-grow: 1;
                                        flex-shrink: 1;
                                        font-family: 'BentonSans-Bold';
                                        color: $general-nav-main-text-color-mobile;
                                        font-size: 1rem;
                                        padding: 1.2rem 0;
                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }
                                    button {
                                        flex-grow: 0;
                                        flex-shrink: 0;
                                        width: 40px;
                                        height: 40px;
                                        font-size: 2rem;
                                        outline: none;
                                        color: $general-nav-main-text-color-mobile;
                                        box-shadow: none;
                                        padding: 0;
                                        background-color: transparent;
                                    }
                                }
                            }
                            .card-body {
                                padding: 0 0 .75rem;
                                border-bottom: 1px solid $general-nav-main-text-color-mobile;
                                ul {
                                    list-style: none;
                                    margin-bottom: 0;
                                    padding-left: 1.5rem;
                                    li {
                                        a {
                                            line-height: 1.2em;
                                            font-family: 'BentonSans-Medium';
                                            text-transform: uppercase;
                                            font-size: .9rem;
                                            letter-spacing: .03em;
                                            padding: .45rem 0;
                                            color: $general-nav-second-text-color;
                                            &:hover {
                                                text-decoration: underline;
                                            }
                                        }
                                        ul {
                                            li {
                                                a {
                                                    font-family: 'BentonSans-Book';
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    & > a {
                        display: block;
                        font-family: 'BentonSans-Bold';
                        color: $general-nav-main-text-color-mobile;
                        font-size: 1rem;
                        padding: 1rem 0;
                        span {
                            margin-right: .5rem;
                        }
                    }
                }
            }
        }
        .container {
            position: relative;
            overflow: hidden;
            padding: 0;
            height: 100%;
            .navbar-menu-mobile-heading {
                height: $navigation-height-general-mobile;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                z-index: 100;
                padding: 0 1rem;
                span {
                    flex-grow: 1;
                    flex-shrink: 0;
                    font-family: 'BentonSans-Regular';
                    font-size: .95rem;
                    letter-spacing: .02em;
                }
                .navbar-close {
                    flex-grow: 0;
                    flex-shrink: 0;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    padding: 0;
                    height: 20px;
                }
            }
            .navbar-menu-mobile-body {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0;
                z-index: 99;
                background-color: transparent;
                padding: 0 1rem;
                overflow-y: scroll;
            }
        }
    }
    &.small {
        height: $navigation-height-mobile-small;
        .navbar {
            height: $navigation-height-mobile-small;
        }
        .navbar-menu-mobile {
            height: $navigation-height-mobile-small;
        }
        .container {
            .navbar-menu-mobile-heading {
                height: $navigation-height-general-mobile-small;
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    #component-navigation {
        .navbar {
            padding: 0 1rem;
            .navbar-brand {
                margin-right: 1rem;
                img {
                    height: 48px;
                }
            }
            .navbar-actions-mobile {
                .navbar-open {
                    margin-left: 2rem;
                }
            }
        }
        .navbar-menu-mobile {
            .container {
                padding: 0 1rem;
                .navbar-menu-mobile-body {
                    left: 1rem;
                    right: 1rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    #component-navigation {
        transition: height .3s ease-in;
        height: $navigation-height-desktop;
        #navigation-products-desktop {
            transition: height .3s ease-in;
            height: $navigation-height-products-desktop;
            border-bottom: 1px solid $products-nav-borders-color;
            background-color: $products-nav-main-color;
            overflow: hidden;
            .row {
                padding: 0;
                height: 100%;
                margin: 0 auto;
                .col-auto {
                    border-right: 1px solid $products-nav-borders-color;
                    .navbar-product-link {
                        font-family: 'Gotham-Book';
                        font-size: .875rem;
                        line-height: 3.9em;
                        color: $products-nav-main-text-color;
                        cursor: pointer;
                        opacity: .6;
                        transition: opacity .3s ease-in, line-height .3s ease-in;
                        &:hover {
                            text-decoration: none;
                            opacity: 1;
                        }
                        &.disabled {
                            cursor: default;
                            opacity: 1;
                        }
                        sup {
                            font-size: .5em;
                            position: relative;
                            top: -1em;
                        }
                    }
                }
                .col {
                    padding: 0;
                    a {
                        font-family: 'BentonSans-Regular';
                        font-size: .8125rem;
                        line-height: 4.2em;
                        transition: line-height .3s ease-in;
                        color: $products-nav-second-text-color;
                        text-decoration: underline;
                        margin-left: 1rem;
                        &:hover {
                            text-decoration: none;
                        }
                        .icon {
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            position: relative;
                            top: 4px;
                            margin-right: .5rem;
                            &:before {
                                display: none;
                            }
                            &.icon-envelope {
                                background-image: url(/images/icon-envelope-gray.svg);
                            }
                            &.icon-user {
                                background-image: url(/images/icon-user-gray.svg);
                            }
                        }
                    }
                }
            }
        }
        .navbar {
            height: $navigation-height-general-desktop;
            transition: height .3s ease-in;
            #navigation-general-desktop {
                height: $navigation-height-general-desktop;
                transition: height .3s ease-in;
                max-width: calc(100% - 160px);
                & > .navbar-nav {
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: stretch;
                    height: 100%;
                    & > .nav-item {
                        position: relative;
                        max-width: 200px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        & > ul {
                            max-width: 320px;
                            position: absolute;
                            background-color: $general-nav-main-transparent-color;
                            top: calc(100% - 1px);
                            right: 50%;
                            transform: translateX(50%);
                            min-width: 100%;
                            max-height: 0;
                            transition: max-height .3s ease-in;
                            list-style: none;
                            width: auto;
                            padding: 0;
                            overflow: hidden;
                            li {
                                padding: 1rem 3rem;
                                text-align: left;
                                position: relative;
                                a {
                                    font-family: 'BentonSans-Medium';
                                    color: $general-nav-second-text-color;
                                    text-transform: uppercase;
                                    line-height: 1.2em;
                                    letter-spacing: .04em;
                                }
                                &:first-child {
                                    padding-top: 3rem;
                                }
                                &:last-child {
                                    padding-bottom: 3rem;
                                }
                                & > ul {
                                    width: auto;
                                    padding: 0;
                                    list-style: none;
                                    margin-bottom: 0;
                                    padding-top: .5rem;
                                    li {
                                        padding: .5rem 0 .5rem 1rem !important;
                                        a {
                                            font-family: 'BentonSans-Book';
                                        }
                                        &:last-child {
                                            padding-bottom: 0 !important;
                                        }
                                    }
                                }
                            }
                        }
                        a {
                            font-family: 'BentonSans-Bold';
                            font-size: .875rem;
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                        & > a {
                            color: $general-nav-main-text-color-desktop;
                            transition: color .3s ease-in;
                            padding-left: 2rem;
                            padding-right: 2rem;
                        }
                        &:after {
                            content: '';
                            display: block;
                            width: 130px;
                            height: 0;
                            position: absolute;
                            margin: 0 auto;
                            bottom: 1px;
                            background-color: $general-nav-main-color;
                            left: 0;
                            right: 0;
                            transition: height .3s ease-in;
                        }
                        &:hover {
                            & > ul {
                                max-height: 600px;
                                & > li {
                                    & > a {
                                        max-height: 200px;
                                    }
                                }
                            }
                            &:after {
                                height: 8px;
                            }
                            & > a {
                                color: $general-nav-main-color;
                            }
                        }
                        &:last-child {
                            & > ul {
                                right: 0;
                                transform: translateX(0);
                            }
                        }
                    }
                }
            }
            .navbar-brand {
                img {
                    height: 80px;
                    transition: height .3s ease-in;
                }
            }
            .navbar-actions-mobile {
                display: none;
            }
        }
        &.small {
            height: $navigation-height-desktop-small;
            #navigation-products-desktop {
                height: $navigation-height-products-desktop-small;
                .row {
                    .col-auto {
                        .navbar-product-link {
                            line-height: 2.33em;
                        }
                    }
                    .col {
                        a {
                            line-height: 2.57em;
                        }
                    }
                }
            }
            .navbar {
                height: $navigation-height-general-desktop-small;
                #navigation-general-desktop {
                    height: $navigation-height-general-desktop-small;
                }
            }
            .navbar-brand {
                img {
                    height: 48px;
                }
            }
        }
        .navbar-menu-desktop {
            display: block;
        }
        .navbar-menu-mobile {
            display: none;
        }
    }
}
@include media-breakpoint-up(xl) {
    #component-navigation {
        .navbar {
            #navigation-general-desktop {
                & > .navbar-nav {
                    & > .nav-item {
                        &:last-child {
                            & > ul {
                                right: 50%;
                                transform: translateX(50%);
                            }
                        }
                    }
                }
            }
        }
    }
}

.section-wound-care-challenges #nav-section-wound-care-challenges > a,
.section-why-puraply-am #nav-section-why-puraply-am > a,
.section-evidence-outcomes #nav-section-evidence-outcomes > a,
.section-provider-support #nav-section-provider-support > a {
    color: $main-second-text-color !important;
}

.section-contact #nav-section-contact {
    font-family: 'BentonSans-Bold' !important;
    text-decoration: none !important;
    &:hover {
        text-decoration: underline !important;
    }
}