@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';

.component-accordion {
    border-bottom: 4px solid $main-second-text-color;
    .card {
        border-radius: 0;
        border-width: 0;
        background-color: transparent;
        .card-header {
            border-bottom: 0;
            border-top: 4px solid $main-second-text-color;
            padding: 1rem 0 0;
            border-radius: 0;
            background-color: transparent;
            button {
                padding: 0;
                color: $main-second-text-color;
                text-decoration: none;
                width: 100%;
                div {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: .5rem;
                    h2 {
                        padding-top: .25rem;
                        font-family: 'BentonSans-Black';
                        font-size: 1rem;
                        margin-bottom: 0;
                        text-align: left;
                        color: $main-second-text-color;
                        line-height: 1.2em;
                        white-space: normal;
                    }
                    img {
                        margin-left: 1em;
                    }
                }
                p {
                    font-family: 'BentonSans-Regular';
                    font-size: 1rem;
                    text-align: left;
                    color: $main-text-color;
                    text-decoration: none;
                    overflow: hidden;
                    margin-right: 40px;
                    max-height: 64px;
                    white-space: normal;
                    &.showing {
                        max-height: 100rem;
                    }
                    &.shown {
                        max-height: 100rem;
                    }
                    &.hiding {
                        max-height: 64px;
                        transition: max-height .35s ease-in;
                    }
                }
                & > *:last-child {
                    margin-bottom: 1rem;
                }
                &[aria-expanded=false] {
                    div {
                        .collapse-icon {
                            display: none;
                        }
                    }
                    p {
                        max-height: 64px;
                        position: relative;
                    }
                }
                &[aria-expanded=true] {
                    div {
                        .expand-icon {
                            display: none;
                        }
                    }
                }
            }
            
        }
        .card-body {
            padding: 0 0 3rem;
            & > *:last-child {
                margin-bottom: 0;
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-accordion {
        .card {
            .card-header {
                button {
                    div {
                        h2 {
                            font-size: 1.15rem;
                        }
                    }
                    p {
                        font-size: 1.0625rem;
                    }
                }
            }
        }
    }
}