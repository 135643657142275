@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.component-form-contact {
    .message-success {
        display: none;
    }
    .container-form {
        width: 100%;
        .form-holder {
            padding-top: 20px;
            position: relative;
            .npi-number {
                display: block;
                width: calc(100% - 37px);
            }
            .zip-code {
                input {
                    max-width: 100px;
                }
            }
            p {
                font-size: 1rem;
            }
        }
    }
    &.success {
        .message-success {
            display: block;
        }
        .container-form {
            display: none;
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-form-contact {
        .container-form {
            .form-holder {
                .npi-number {
                    width: 100%;
                }
                .form-group {
                    .component-select,
                    .form-control {
                        width: 40%;
                    }
                    &.form-check {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-form-contact {
        .container-form {
            .form-holder {
                .form-group {
                    .form-group {
                        .component-select,
                        .form-control {
                            width: 35%;
                        }
                        &.form-check {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}