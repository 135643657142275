@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';

.component-tabs {
    .nav {
        justify-content: center;
        align-items: flex-end;
        .nav-item {
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 0;
            .nav-link {
                padding: 0 1rem;
                font-family: 'BentonSans-Black';
                font-size: 1.0625rem;
                line-height: 1.85em;
                color: $main-second-tab-text-color;
                border-bottom: 5px solid $main-second-tab-text-color;
                border-radius: 0;
                background-color: transparent;
                text-decoration: none;
                &.active {
                    color: $main-second-text-color;
                    border-bottom: 5px solid $main-second-text-color;
                    background-color: transparent;
                }
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
    .tab-content {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;

        overflow: hidden;

        .tab-pane {
            min-width: 100%;
            order: 1;
            padding: 40px 0 0;
            display: block;
            visibility: hidden;
            width: 100%;
            & > * {
                transform: translateY(6px);
                opacity: 0;
                transition: transform 1s ease-in, opacity 1s ease-in;
            }
            &.active {
                visibility: visible;
                order: 0;
                & > * {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }
}