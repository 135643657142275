@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-content {
    padding-top: $content-padding-top;
    padding-bottom: $content-padding-bottom;

    .product-bg {
        background: url('/images/bg-product-image.webp') no-repeat center 75%;
        background-size: 100%;
    }

    &.quote {
        text-align: center;
        color: $bg-color-purple;

        .symbol-quote {
            margin-top: 3rem;
            font-family: 'BentonSans-Bold';
            font-size: 1.25rem;
            &::before {
                position: relative;
                display: block;
                width: 100%;
                height: 20px;
                top: -0.5rem;
                content: '“';
                line-height: 0.2em;
                font-size: 8rem;
                line-height: 20px;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: bold;
                text-align: center;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .component-content {
        .product-bg {
            background-size: 100%;
        }

        &.quote {
            .symbol-quote {
                margin-top: 4rem;
                margin-bottom: 1.5rem;
                font-size: 2rem;
                &::before {
                    top: 0;
                    font-size: 12rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .component-content {
        .product-bg {
            background-position: center top;
        }
    }
}
