@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';
.component-jumbotron {
    height: auto;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    &.orange,
    &.orange-purple,
    &.blue {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        .container {
            height: 30vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            * {
                color: #ffffff;
                margin-bottom: 0;
                line-height: 1em;
            }
            h1 {
                font-size: 7vw;
                margin-bottom: .25em;
                font-family: 'BentonSans-Black';
                sup {
                    font-size: .85em;
                    top: -.2em;
                }
            }
            p {
                font-size: 3vw;
                line-height: 1.35;
            }
            & > *:last-child {
                margin-bottom: 0;
            }
        }
        &.fluid-height {
            .container {
                height: auto;
                padding-top: 4vw;
                padding-bottom: 4vw;
            }
        }
    }
    &.orange-purple {
        background: $general-nav-main-color;
        background: linear-gradient(90deg, $main-forth-text-color 0%, $general-nav-main-color 100%);
    }
    &.orange {
        background-image: url(/images/bg-orange-gradient.webp);
    }
    &.blue {
        background-image: url(/images/bg-blue-gradient.webp);
    }
    &.slider {
        height: 88vw;
        margin-bottom: 0;
        .carousel {
            height: 100%;
            .carousel-indicators {
                margin-bottom: 0;
                bottom: 20px;
                li {
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    margin-left: 6px;
                    margin-right: 6px;
                }
            }
            .carousel-inner {
                height: 100%;
                .carousel-item {
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    .container {
                        height: inherit;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding: 10vw 15px 18vw;
                        h1 {
                            font-size: 1.5rem;
                            line-height: 1;
                            opacity: 0;
                            transition: opacity 1s ease-in-out .1s;
                            padding-top: .2em;

                            &.long-text {
                                font-size: 4.5vw;
                                line-height: 1.2em;
                            }
                        }
                        p {
                            opacity: 0;
                            transition: opacity 1s ease-in-out .1s;
                        }
                        .btn {
                            background-color: $main-second-text-color;
                            color: #ffffff;
                            transition: background-color .3s ease-out, color .3s ease-out, opacity 1s ease-in-out .1s;
                            opacity: 0;
                            &:hover {
                                background-color: #ffffff;
                                color: $main-second-text-color;
                            }
                            &.orange {
                                background-color: $main-forth-text-color;
                                &:hover {
                                    background-color: #ffffff;
                                    color: $main-forth-text-color;
                                }
                            }
                        }
                        &.align-right {
                            min-width: 95%;
                            padding-left: 45%;
                            padding-top: 10vw;
                            padding-right: 0;
                        }
                    }
                    &.active {
                        .container {
                            h1, p, .btn {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .carousel-control-prev {
                display: none;
                width: auto;
                justify-content: flex-end;
                .carousel-control-prev-icon {
                    background-image: none;
                    font-family: 'ogn-puraply';
                    color: #ffffff;
                    font-size: 2.66rem;
                    width: auto;
                    height: auto;
                    &:before {
                        content: "\e901";
                        text-shadow: 0px 0px 5px rgba(0,0,0,.83);
                    }
                }
            }
            .carousel-control-next {
                display: none;
                width: auto;
                justify-content: flex-start;
                .carousel-control-next-icon {
                    background-image: none;
                    font-family: 'ogn-puraply';
                    color: #ffffff;
                    font-size: 2.66rem;
                    width: auto;
                    height: auto;
                    &:before {
                        content: "\e900";
                        text-shadow: 0px 0px 5px rgba(0,0,0,.83);
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-jumbotron {
        &.orange,
        &.orange-purple,
        &.blue {
            .container {
                height: 150px;
                h1 {
                    font-size: 2rem;
                }
                p {
                    font-size: 1rem;
                }
            }
        }
        &.slider {
            min-height: 475px;
            .carousel {
                .carousel-inner {
                    .carousel-item {
                        .container {
                            h1 {
                                font-size: 2.375rem;
                                &.long-text {
                                    font-size: 1.6rem;
                                    line-height: 1.2em;
                                }
                            }
                            &.align-right {
                                padding-left: calc(47.5% + 2vw);
                                padding-top: 15vw;
                            }
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-jumbotron {
        &.slider {
            min-height: 324px;
            height: 45vw;
            .carousel {
                .carousel-inner {
                    .carousel-item {
                        .container {
                            padding: 3vw 15px 10vw;
                            h1 {
                                font-size: 2.25rem;
                                &.long-text {
                                    font-size: 1.6rem;
                                    line-height: 1.1em;
                                }
                            }
                            &.align-right {
                                min-width: 720px;
                                padding-left: calc(360px + 8vw);
                                padding-top: 8.5vw;
                            }
                        }
                    }
                }
                .carousel-control-prev,
                .carousel-control-next {
                    display: flex;
                    width: calc((100vw - 720px)/2);
                }
                .carousel-control-prev {
                    padding-right: 20px;
                }
                .carousel-control-next {
                    padding-left: 20px;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-jumbotron {
        &.orange,
        &.orange-purple,
        &.blue {
            .container {
                height: 192px;
                * {
                    width: 85%;
                }
                h1 {
                    font-size: 2.375rem;
                }
                p {
                    font-size: 1.15rem;
                }
            }
        }
        &.slider {
            min-height: 432px;
            .carousel {
                .carousel-indicators {
                    li {
                        width: 20px;
                        height: 20px;
                    }
                }
                .carousel-inner {
                    .carousel-item {
                        .container {
                            padding: 3vw 0 10vw 15px;
                            h1 {
                                font-size: 2.375rem;
                                &.long-text {
                                    font-size: 2rem;
                                }
                            }
                            &.align-right {
                                min-width: 960px;
                                padding-left: calc(480px + 9vw);
                                padding-top: 8.5vw;
                            }
                        }
                    }
                }
                .carousel-control-prev,
                .carousel-control-next {
                    width: calc((100vw - 960px)/2);
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-jumbotron {
        &.orange,
        &.orange-purple,
        &.blue {
            .container {
                height: 228px;
                h1 {
                    font-size: 2.875rem;
                }
            }
        }
        &.slider {
            min-height: 513px;
            max-height: 700px;
            .carousel {
                .carousel-inner {
                    .carousel-item {
                        .container {
                            h1 {
                                font-size: 3rem;
                                line-height: 1.1em;
                                &.long-text {
                                    font-size: 2.6rem;
                                }
                            }
                            &.align-right {
                                min-width: 1140px;
                                padding-left: calc(570px + 6.1vw);
                                padding-top: 10vw;
                            }
                        }
                    }
                }
                .carousel-control-prev,
                .carousel-control-next {
                    width: calc((100vw - 1140px)/2);
                }
            }
        }
    }
}
@include media-breakpoint-down(sm) {
    .component-jumbotron {
        .carousel {
            .carousel-inner {
                #carousel-item-1 {
                    .container {
                        justify-content: flex-start !important;
                    }
                }
            }
        }
    }
}
