@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.component-circle-of-care {
    .row {
        & > div:first-child {
            text-align: center;
            margin-bottom: 20px;
        }
        & > div:last-child {
            text-align: left;
        }
    }
    img {
        max-width: 235px;
    }
}
@include media-breakpoint-up(lg) {
    .component-circle-of-care {
        .row {
            align-items: flex-start;
            & > div:first-child {
                flex-grow: 0;
                flex-shrink: 0;
                margin-bottom: 0;
                margin-right: 20px;
                flex-basis: 235px;
            }
            & > div:last-child {
                flex-grow: 1;
                flex-shrink: 1;
                padding-right: 13%;
            }
        }
        ul {
            margin-bottom: 2rem;
        }
    }
}
