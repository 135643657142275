@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

@import '../../css/variables';

.component-references {
    padding-top: 40px;
    padding-bottom: 40px;
    .container {
        h3 {
            margin-bottom: 0.25rem;
            font-family: 'BentonSans-Bold';
            font-size: .75rem;
            color: $main-text-color;
        }
        ol {
            padding: 0;
            margin: 0;
            counter-reset: item;
            line-height: 0;
            li {
                display: inline;
                list-style-type: none;
                counter-increment: item;
                font-family: 'BentonSans-Book';
                font-size: .75rem;
                line-height: 1.4em;
                white-space: nowrap;
                &:before {
                    font-family: 'BentonSans-Medium';
                    padding-right: 0.2em;
                    text-align: left;
                    content: counter(item) ".";
                }
                .text-break {
                    word-break: break-all !important;
                    overflow-wrap: break-word !important;
                }
                & > span {
                    margin-right: 0.25rem;
                    white-space: normal;
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-references {
        padding-top: 90px;
        padding-bottom: 100px;
    }
}